<template>
  <div class="homeContainer">
    <div class="topView">
      				
      <div class="topMainBtn" :style="select_tab=='bulletin' ? 'background-color: #4880F5;' : '' " @click="changeView('bulletin')">
        Bulletin
      </div>
      <div class="topMainBtn" :style="select_tab=='scoreAward' ? 'background-color: #4880F5;' : '' " @click="changeView('scoreAward')">
        Scores & Awards
      </div>
      <div class="topMainBtn" :style="select_tab=='ResultRelease' ? 'background-color: #4880F5;' : '' " @click="changeView('ResultRelease')">
        Result Release
      </div>
      <div class="topMainBtn" :style="select_tab=='ResultSchool' ? 'background-color: #4880F5;' : '' " @click="changeView('ResultSchool')">
        Result by School
      </div>
      <div class="topMainBtn" :style="select_tab=='Advancement' ? 'background-color: #4880F5;' : '' " @click="changeView('Advancement')">
        Advancement
      </div>
      <div class="topMainBtn" :style="select_tab=='Admit' ? 'background-color: #4880F5;' : '' " @click="changeView('Admit')">
        Admit
      </div>
  


      <div class="topLog">
        <div class="studentNameStyle" @click="changeView('')">
          {{ student_name }}
        </div>
        <div class="logoutStyle" @click="changeView('logout')">
          Log out
        </div>
      </div>

      

    </div>
    <div class="main">

   

      <!-- <div class="mainRight"> -->
        <router-view></router-view>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import {
  logout,
  getUserType,
  getUserName,
  getAccountName,
  getAvatarUrl,
  getProjectName, getProjectCode
} from "../utils/store";
import {
  getProjectInfo
} from '../api/index'

export default {
  data() {
    return {
      nav: [
        {
          name: "Notices & Information",
          selected: require("../assets/nav/peixun1.png"),
          unselected: require("../assets/nav/peixun.png"),
          router: "/announcements",
        },
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/studentlist",
        },
        // {
        //   name: "Rankings",
        //   selected: require("../assets/nav/chengji1.png"),
        //   unselected: require("../assets/nav/chengji.png"),
        //   router: "/score",
        // },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        {
          name: "Contact Us",
          selected: require("../assets/nav/xiaoxi1.png"),
          unselected: require("../assets/nav/xiaoxi.png"),
          router: "/message",
        },
        //奖项
        // {name:'消息社交',selected:require('../assets/nav/award1.png'),unselected:require('../assets/nav/award.png'),router:'/award'},//奖项
        // {name: '退出登录', selected: require('../assets/nav/tuichu1.png'), unselected: require('../assets/nav/tuichu.png')}
      ],
      navContact: [
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/teacherlist",
        },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        // {
        //   name: "退出登录",
        //   selected: require("../assets/nav/tuichu1.png"),
        //   unselected: require("../assets/nav/tuichu.png"),
        // },
      ],
      navEmployee: [
        // {
        //   name: "赛前培训",
        //   selected: require("../assets/nav/peixun1.png"),
        //   unselected: require("../assets/nav/peixun.png"),
        //   router: "/peixunadmin",
        // },
        {
          name: "Events",
          selected: require("../assets/nav/bisai1.png"),
          unselected: require("../assets/nav/bisai.png"),
          router: "/employeelist",
        },
        {
          name: "Personal Information",
          selected: require("../assets/nav/geren1.png"),
          unselected: require("../assets/nav/geren.png"),
          router: "/personalinfo",
        },
        // {
        //   name: "监考",
        //   selected: require("../assets/nav/bisai1.png"),
        //   unselected: require("../assets/nav/bisai.png"),
        //   router: "/examadminlist",
        // },
        // // {name:'消息',selected:require('../assets/nav/xiaoxi1.png'),unselected:require('../assets/nav/xiaoxi.png'),router:'/message'},
        // {
        //   name: "退出登录",
        //   selected: require("../assets/nav/tuichu1.png"),
        //   unselected: require("../assets/nav/tuichu.png"),
        // },
      ],
      user_type: getUserType(),
      project_name: getProjectName(),
      currentNav: "/peixun",
      student_name: "",
      student_school: "",
      application_project: "",
      avatar_url: "",
      select_tab:'bulletin'
    };
  },
  watch: {
    $route(to, from) {
      this.currentNav = this.$route.path;
    },
  },
  mounted() {
    // this.$nextTick(() => {
    //   // 禁用右键
    //   document.oncontextmenu = new Function("event.returnValue=false");
    //   // 禁用选择
    //   document.onselectstart = new Function("event.returnValue=false");
    // });
    this.student_name = getUserName();
    this.student_school = getAccountName();
    this.avatar_url = getAvatarUrl();
    // this.currentNav = this.$route.path;
    this.requestProjectConfig()
  },
  methods: {

    requestProjectConfig() {
      // getProjectInfo(getProjectCode()).then((res) => {
      //   let project = res.data
      //   if (project.project_exam_system_results_model_show && project.project_exam_system_results_model_show == 'show') {
      //     this.nav.push(
      //         {
      //           name: "Results",
      //           selected: require("../assets/nav/award1.png"),
      //           unselected: require("../assets/nav/award.png"),
      //           router: "/newaward",
      //         },
      //     )
      //   }
      // })
    },

    logout() {
      logout();
      location.reload();
    },
    changeView(item) {


      if (item == 'logout'){
        this.$confirm("Are you sure you want to log out?", "Logout Confirmation.", {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }).then(() => {
          this.logout();
        });
        return
      }

      this.select_tab = item


      if (item == 'scoreAward'){
        this.$router.push('/scoreAward');
        return
      }
      if (item == 'bulletin'){
        this.$router.push('/adminbulletin');
        return
      }
      if (item == 'ResultRelease'){
        this.$router.push('/resultrelease');
        return
      }
      
      if (item == 'ResultSchool'){
        // this.$router.push('/learningmaterials');
        return
      }
      if (item == 'Admit'){
        // this.$router.push('/learninghub');
        return
      }      

      if (item == 'Advancement'){
        this.$router.push('/advancement');
        return
      }   
      

      if (item.router) {
        this.currentNav = item.router;
        this.$router.push(item.router);
      } else {
        this.$confirm("Are you sure you want to exit?", "Tips", {
          confirmButtonText: "confirm",
          cancelButtonText: "cancel",
          type: "warning",
        }).then(() => {
          this.logout();
        });
      }
    },
  },
};
</script>

<style scoped="scoped" lang="less">
.content-logout {
  box-sizing: border-box;
  width: 380px;
  position: absolute;
  bottom: 0;
  padding-left: 70px;
  height: 10vh;
  background-color: #FFFAF4;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 2vh;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.6);

  .iconImg {
    height: 3vh;
    margin-right: 6px;
  }
}

.menuStyle {
  color: #C5C9DE;
  align-content: center;
  text-align: left;
  margin-top: 20px;
  margin-left: 50px;
  font-size: 15px;
}

.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .topView {
    position: relative;
    flex-shrink: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    // align-items: center;
    // justify-content: center;
    height: 80px;
    // background-image: url("../assets/newTopBg.png");
    background-repeat: no-repeat;
    background-color: white;
    background-size: cover;
    // background: #00348b;
    min-width: 1100px;
    cursor: pointer;

    .topLog{
      width: 16vw;
      background-color: #072D76;
    }
    .topMainBtn{
      width: 14vw;
      height: 80px;
      color: white;
      line-height: 80px;
      text-align: center;
      background-color: #072D76;
    }

    .logoutStyle {
      // position: absolute;
      // margin-left: 10%;
      margin-right: 20px;
      // margin-right: 20px;
      height: 20px;
      // width: 80%;
      float: right;
      width: 50%;
      margin-top: 10px;
      // background-color: gray;
      // border: 1px solid #B2B6C5;
      // border-radius: 3px;
      color: white;
      text-align: right;
      line-height: 20px;
    }

    .studentNameStyle {
      margin-left: 0px;
      margin-right: 20px;
      margin-top: 20px;
      font-size: 13px;
      color: white;
      text-align: right;
    }

    .studentlogo {
      position: absolute;
      margin-top: 20px;
      margin-left: 40px;
      width: 22px;
      height: 20px;
      // background-color: red;
      // border-radius: 10px;
    }

  }

  .main {
    padding: 0;
    // flex: 1;
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: auto;   
  }
}
</style>
